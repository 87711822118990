<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable no-var -->
<!-- eslint-disable max-len -->
<template>
  <div class="page-payment hardware-padding back-btn">
    <n-loader :loading="isLoading" />

    <!-- <c-phone-verify
      v-if="showVerify" 
      :amount="value" 
      :show.sync="showVerify" 
      :to-cards-id="card.id"
      :card-holder="cardHolder" 
      :card-number="$form.input('cardNumber').value" 
      :mask="card.title"
      :card-identifier="card.identifier" 
      :need-check="needCheck" 
      :verification-type="needVerificationType"
      :otp-data="otpData" 
      :document-id="documentId" 
      @loading="changeLoadingState" 
      @update-needCheck="updateNeedCheck" 
    /> -->

    <c-go-back backroute="mobile.index" :title="$app.secure.clean($n.t('1.12.5'))" />
    <div v-show="showContainer" class="container">
      <c-frame v-if="show" bacroute="mobile.payment" :url="frameUrl" :show.sync="show" />
      <div class="item">
        <div class="available-amount">
          <div>
            <t name="1.6.5" />
          </div>
          <div>{{ $n.formatNumber(user.salaryStats.availableToTake) }} ₸</div>
        </div>
      </div>

      <div v-show="user.salaryStats.startAmount !== 0" class="item">
        <div class="available-amount">
          <div
            :style="user.salaryStats?.availableAmount <= 1 && user.salaryStats.startAmount !== 0 ?
              '' : ''">
            <t name="1.6.12" />
          </div>
          <div>{{ user.salaryStats.startAmount !== 0 ? user.salaryStats?.availableAmount : '&#8734;' }}</div>
        </div>
      </div>

      <!-- <div v-show="user.company?.payoutProvider === 'pitech'" class="block-title">
        <t name="1.12.1" />
      </div>
      <div v-show="user.company?.payoutProvider === 'pitech'" class="item choose-card">
        <c-select :danger="$var('selectMessage')" :value.sync="card" :data="cards" text @addcard="addCard()" />
      </div> -->

      <div v-show="user.company?.payoutProvider === 'woopay'" class="block-title">
        <t name="1.12.1" />
      </div>
      <div v-show="user.company?.payoutProvider === 'woopay'" class="item choose-card">
        <card-select
          :danger="$var('selectMessage')" :value.sync="card" :data="cards" text
          @addcard="addProviderCard('showAddWoopayCard')" />
      </div>
      <div v-show="user.company?.payoutProvider === 'bereke'" class="block-title">
        <t name="1.12.1" />
      </div>
      <div v-show="user.company?.payoutProvider === 'bereke'" class="item choose-card">
        <card-select
          :danger="$var('selectMessage')" :value.sync="card" :data="cards" text
          @addcard="addProviderCard('showAddBerekeCard')" />
      </div>


      <div class="take-sum">
        <!-- <n-form @submit="takeSum"> -->
        <n-form>
          <div v-if="showAddWoopayCard">
            <label for="cardNumber">
              <t name="1.12.8" />:
            </label>
            <div class="item">
              <n-input
                id="cardNumber" v-bind="$form.input('cardNumber')" placeholder="1234 1234 1234 1234" type="tel"
                name="cardNumber" class="take-input" @blur="validateCardNumber" />
            </div>

            <div>
              <p v-if="isAllNumberCard" class="danger">
                <t name="1.12.13" />
              </p>

              <p v-if="isValidCard" class="danger">
                <t name="1.12.14" />
              </p>
            </div>

            <label for="cardName">
              <t name="1.12.9" />:
            </label>
            <div class="item">
              <n-input
                id="cardName" v-model="cardHolder" placeholder="ИМЯ ФАМИЛИЯ" type="text" name="cardName"
                class="take-input" @blur="validateCardHolderName" @input="formatCardHolder" />
            </div>

            <div>
              <p v-if="isLatin" class="danger">
                <t name="1.12.12" />
              </p>
              <p v-if="isNameTooLong" class="danger">
                <t name="1.12.10" />
              </p>
              <p v-if="isSingleCharacterEntered" class="danger">
                <t name="1.12.11" />
              </p>
            </div>
          </div>
          <div v-if="showAddBerekeCard">
            <label for="cardNumber">
              <t name="1.12.8" />:
            </label>
            <div class="item">
              <n-input
                id="cardNumber" v-bind="$form.input('cardNumber')" placeholder="1234 1234 1234 1234" type="tel"
                name="cardNumber" class="take-input" @blur="validateCardNumber" />
            </div>

            <div>
              <p v-if="isAllNumberCard" class="danger">
                <t name="1.12.13" />
              </p>

              <p v-if="isValidCard" class="danger">
                <t name="1.12.14" />
              </p>
            </div>

            <label for="cardName">
              <t name="1.12.9" />:
            </label>
            <div class="item">
              <n-input
                id="cardName" v-model="cardHolder" placeholder="ИМЯ ФАМИЛИЯ" type="text" name="cardName"
                class="take-input" @blur="validateCardHolderName" @input="formatCardHolder" />
            </div>
            <div>
              <p v-if="isLatin" class="danger">
                <t name="1.12.12" />
              </p>
              <p v-if="isNameTooLong" class="danger">
                <t name="1.12.10" />
              </p>
              <p v-if="isSingleCharacterEntered" class="danger">
                <t name="1.12.11" />
              </p>
            </div>
          </div>
          <div class="block-title">
            <t name="1.12.2" />
          </div>
          <div class="subtitle">
            <t name="1.12.3" />
          </div>
          <div class="item">
            <n-input
              v-model="value" :danger="$var('message')" class="take-input" placeholder="0 ₸" step="1"
              type="tel" />
          </div>
        </n-form>

        <!-- Вывод суммы с комиссией или без -->
        <div v-if="company.getCommission === 'afterPay'" class="commission">
          <t name="1.12.4" />: {{ $n.formatNumber(calculateCommission(value)) }} ₸
        </div>
        <div v-else class="commission">
          <t name="1.12.7" />: {{ withdrawalAmount }} ₸
          <br>
          <t name="1.12.4" />: {{ $n.formatNumber(calculateCommission(value)) }} ₸
        </div>
      </div>

      <!-- Чекбокс -->
      <div class="agreement" @click="toggleAgreement">
        <input id="agreement" v-model="agreement" type="checkbox" class="agreement__input" />
        <span class="agreement__text block-title" for="agreement">
          <t name="1.9.5" />
          <span class="agreement__btn" @click.stop="openModalAgreement">
            <t name="1.9.5.1" />
          </span>
        </span>
      </div>

      <!-- <div v-show="user.company?.payoutProvider === 'pitech'" class="button-place">
        <n-button
          class="user-default-button" type="submit"
          :disabled="isBtnDisabled || isLatin || isSingleCharacterEntered || isNameTooLong" @click="takeSum">
          <t name="1.4.8" />
          <div v-if="company.getCommission === 'afterPay'">
            {{ $n.formatNumber(+value + +calculateCommission(value)) }} ₸
          </div>
          <div v-else>
            {{ $n.formatNumber(value) }} ₸
          </div>
        </n-button>
      </div> -->

      <div v-show="user.company?.payoutProvider === 'woopay'" class="button-place">
        <n-button
          class="user-default-button" :class="{
            'user-default-button-disable': isDisableBtn,
          }" type="submit" :disabled="isDisableBtn" @click="takeSumProvider">
          <t name="1.4.8" />
          <div v-if="company.getCommission === 'afterPay'">
            <template v-if="user.company?.payoutProvider === 'woopay'">
              {{ $n.formatNumber(+value + +calculateCommission(value)) }} ₸
            </template>
            <template v-if="user.company?.payoutProvider === 'bereke'">
              {{ calcAfterPayWithValidation(+value) }} ₸
            </template>
          </div>
          <div v-else>
            {{ $n.formatNumber(+value) }} ₸
          </div>
        </n-button>
      </div>

      <!--      Модальное окно с документом на подписание      -->
      <div class="c-document-viewer">
        <div v-if="showDocumentModal" class="content">
          <div class="header-modal-document">
            <h4 class="text">
              <t name="5.5.3" />
            </h4>
            <n-button class="close-button" flat @click="closeDocumentModal">x</n-button>
          </div>
          <div class="body">
            <div ref="pdfContainer" class="pdf-container">
              <div class="pdf-controls">
                <button class="zoom-button-btn" @click="zoomIn">-</button>
                <button class="zoom-button-btn" @click="zoomOut">+</button>
              </div>
              <div id="pdfContainer"></div>
            </div>
            <n-button class="default-button" :disabled="addSignatureDisable" type="submit" @click="addSignature">
              <t name="5.5.6" />
            </n-button>
          </div>
        </div>
      </div>
      <!--      Модальное окно с камерой      -->
      <!-- <div v-if="showCameraModal" class="c-camera-modal">
        <div class="iframe-container">
          <iframe id="biometric-frame" :src="iframeSrc" allow="camera" frameborder="0"></iframe>
        </div>
      </div> -->

      <!-- Модальное окно с просмотром пользовательского соглашения -->
      <div class="agreement-modal" :class="{ 'agreement-modal__active': showModalAgreement }">
        <p class="agreement-modal__text block-title">
          <t name="1.9.6" />
        </p>
        <n-button class="user-default-button" @click.stop="openModalAgreement">
          <t name="1.9.7" />
        </n-button>
      </div>

    </div>
    <div v-if="showWoopaySuccess">
      <c-modal-success
        :operation-id="woopayOperationId" :amount="woopayAmount" :commission="commission"
        :date-time="woopayDateTime" />
    </div>
    <div v-if="showBerekeSuccess">
      <c-modal-success
        :operation-id="berekeOperationId" :amount="berekeAmount" :commission="berekeCommission"
        :date-time="berekeDateTime" />
    </div>
  </div>
</template>

<script>
import bank from 'assets/bank/bank.svg'
import 'pdfjs-dist/legacy/build/pdf.worker.entry'
import * as pdfjsLib from 'pdfjs-dist'


export default {
  name: 'PaymentPage',
  data() {
    return {
      // eslint-disable-next-line max-len
      // iframeSrc: '',
      // showCameraModal: false,
      // showVerify: false,
      // commissionCoef: {},
      // needSmsVerification: false,
      // needFaceIdentify: false,
      // interval: setInterval(this.decTimer, 1000),
      // timer: 30,
      // otpData: {},
      isLatin: false,
      needFcbVerification: false,
      showContainer: true,
      showDocument: false,
      isSingleCharacterEntered: false,
      isNameTooLong: false,
      showDocumentModal: false,
      needCheck: true,
      document: null,
      documentId: null,
      bank,
      user: $app.auth.user(),
      showAddWoopayCard: false,
      showAddBerekeCard: false,
      showWoopaySuccess: false,
      showBerekeSuccess: false,
      woopayAmount: '',
      woopayDateTime: '',
      woopayOperationId: '',
      berekeAmount: '',
      berekeDateTime: '',
      berekeOperationId: '',
      show: false,
      frameUrl: '',
      card: {},
      cards: [],
      value: '',

      cardHolder: '',
      isBtnDisabled: true,
      minCommission: 0,
      company: {},
      commission: 0,
      berekeCommission: 0,
      link: '',
      code: '',
      hideButton: false,
      isDisabled: true,
      addSignatureDisable: true,
      scale: 1,
      agreement: false,
      showModalAgreement: false,
      isValidCard: null,
      isAllNumberCard: null,
      needVerificationType: null,

      //* новая логика транзакции
      firstStep: null,
      transactionStatus: null,
      transactionUuid: null,

      isLoading: true
    }
  },
  computed: {
    isDisableBtn() {
      return this.isBtnDisabled ||
        this.isLatin ||
        this.isSingleCharacterEntered ||
        this.isNameTooLong ||
        !this.agreement ||
        this.isValidCard ||
        this.isAllNumberCard ||
        !this.value
    },
    startTransactionData() {
      return this.$store.state.app.startTransaction;
    },
    withdrawalAmount() {
      const value = +this.value;
      return value >= 500 ? $n.formatNumber(value - this.calculateCommission(value)) : 0;
    },
    isBlockedEmployer() {
      return this.user.status === 'blocked'
    },
    isBlockedProsperPay() {
      return this.user.useApp === 0
    }
  },
  watch: {
    value() {
      this.value = this.value.replace(/\D/g, '')
    },
    show() {
      if (!this.show) {
        this.getCards()
      }
    },
    card() {
      if (this.card?.id !== 'add') {
        this.isBtnDisabled = false
      } else {
        this.isBtnDisabled = true
      }
    },
    woopaycard() {
      if (this.card?.id !== 'add') {
        this.isBtnDisabled = false
      } else {
        this.isBtnDisabled = true
      }
    },
    berekecard() {
      if (this.card?.id !== 'add') {
        this.isBtnDisabled = false
      } else {
        this.isBtnDisabled = true
      }
    },
    cardNumber() {
      if (this.showAddWoopayCard) {
        if (this.$form.input('cardNumber').value !== '' && this.cardHolder !== '') {
          this.isBtnDisabled = false
        } else {
          this.isBtnDisabled = true
        }
      }
      if (this.showAddBerekeCard) {
        if (this.$form.input('cardNumber').value !== '' && this.cardHolder !== '') {
          this.isBtnDisabled = false
        } else {
          this.isBtnDisabled = true
        }
      }
    },
    cardHolder() {
      if (this.showAddWoopayCard) {
        if (this.$form.input('cardNumber').value !== '' && this.cardHolder !== '') {
          this.isBtnDisabled = false
        } else {
          this.isBtnDisabled = true
        }
      }
      if (this.showAddBerekeCard) {
        if (this.$form.input('cardNumber').value !== '' && this.cardHolder !== '') {
          this.isBtnDisabled = false
        } else {
          this.isBtnDisabled = true
        }
      }
    },
  },
  created() {
    
    $app.auth.needAuth(true)
    this.initRequests()

    this.$form.init({
      value: '',
      cardNumber: ''
    })
    this.$form.rules({
      value: [ 'required', 'biggerThanMin', ],
      cardNumber: [ 'card', ],
    })
    this.$form.masks({
      cardNumber: [ 'card' ],
    })
    
  },
  methods: {
    async initRequests() {
      await this.getCards()
      await this.loadCompany()
      await this.startTransaction()

      this.isLoading = false
    },
    async loadCompany() {
      try {
        const response = await $api.companies.get(this.user.selectedCompany)
        this.company = response.data.content
        this.minCommission = this.company.minCommission
        this.$store.commit('app/setVerificationType', this.company.verificationType)
      } catch (error) {
        console.error("Ошибка загрузки компании");
        $app.ui.notify('Упс... Не удалось получить данные о компании', 'danger')
        this.isLoading = false
      } 
    },
    async getCards() {
      this.cards = []
      this.cards.push({ id: 'add', name: 'add', title: '', bank: '', issuer: 'Новая карта', })
      this.$var('load', true)

      try {
        const response = await $api.payment.getCardsNew()
        if (response.data.content?.length > 0) {
          $n.map(response.data.content, (e) => {
            const cardBin = e.mask.substr(0, 6)
            const bank = $n.getBankIcon(cardBin)
            this.cards.push({
              identifier: e.id,
              id: e.id,
              name: '',
              title: e.mask,
              issuer: '',
              bank: bank.name,
              bankIcon: bank.icon,
              issuerIcon: '',
            })
          })
        }

      } catch (error) {
        $app.ui.notify('Упс... Не удалось получить активные карты', 'danger')
        console.error('An error occurred.');
        this.isLoading = false

      } 
    },
    async startTransaction() {
      const data = {
        companyId: this.user.company.id,
        processName: "transaction"
      }
      try {
        const response = await $api.transactions.start(data)
        this.firstStep = await response.data.data.firstStep
        this.$store.commit('app/setStartTransaction', this.firstStep)
      } catch (error) {
        console.error('An error occurred.');
        $app.ui.notify('Произошла ошибка, попробуйте позднее (код 1)', 'danger')
        this.isLoading = false
      }
    },

    //* Валидация
    validateCardNumber() {
      const numberCard = this.$form.input('cardNumber').value.replace(/\s+/g, '').trim()

      if (numberCard.length !== 16) {
        this.isAllNumberCard = true
        return
      } else {
        this.isAllNumberCard = false
      }

      const digits = numberCard.split('').map(Number);

      const reversedDigits = digits.reverse();
      let sum = 0;

      for (let i = 0; i < reversedDigits.length; i++) {
        let digit = reversedDigits[i];

        if (i % 2 !== 0) {
          digit *= 2;

          if (digit > 9) {
            digit -= 9;
          }
        }
        sum += digit;
      }

      // 4405 6397 7288 6865
      (sum % 10 === 0) ? this.isValidCard = false : this.isValidCard = true;
    },
    validateCardHolderName() {
      if (this.cardHolder === '') {
        this.isLatin = false
        return
      }
      const latinRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/
      this.isLatin = !latinRegex.test(this.cardHolder)
      this.isNameTooLong = this.cardHolder.length > 31
      this.isSingleCharacterEntered = (this.cardHolder.trim().length <= 3) ? true : false
    },
    formatCardHolder() {
      this.cardHolder = this.cardHolder.trim().toUpperCase()
    },

    //* Работа с транзакциями
    handleInvalidSum(value) {
      if (!value) {
        $app.ui.notify(`${$app.secure.clean($n.t('3.20'))}`, 'danger');
      } else {
        value >= 1000 && this.company.minimalTransactionSum && this.company.minimalTransactionSum >= value ?
          $app.ui.notify(`${$app.secure.clean($n.t('3.27'))} ${this.company.minimalTransactionSum} ₸'`, 'danger') :
          $app.ui.notify(`${$app.secure.clean($n.t('3.27'))} 1000 ₸`, 'danger');
      }
    },
    calculateCommission(value) {

      let cardNumber = this.$form.input('cardNumber').value ?
        this.$form.input('cardNumber').value.replace(/\s+/g, '') :
        this.card.title

      let pan = '';

      if (this.cards.length === 1 && !this.cards[0].title && cardNumber) {
        pan = cardNumber;
      } else if (this.cards.length > 1 || (this.cards.length === 1 && this.cards[0].title)) {
        pan = cardNumber;
      }

      if (value > 0) {
        const data = {
          sum: value,
          pan,
        }
        $api.commission.calculate(data).then((response) => {
          if (response) {
            this.commission = response.data.userCom
          }
        }).catch((error) => {
          console.error('An error occurred.');
          $app.ui.notify('Упс... Не удалось получить комиссию', 'danger')

        })
      }
      return this.commission
    },
    calcAfterPayWithValidation(value) {
      const result = parseFloat(value) + this.calculateCommission(value)
      if (this.user.salaryStats.availableToTake < result) {
        this.$var('message', $n.t(3.34))
      }
      return $n.formatNumber(result)
    },
    calculateSumAfterCommission(value) {
      return this.company.getCommission === 'prePay' ?
        value - this.calculateCommission(value) :
        value;
    },
    isSumValid(value) {
      const sumAfterCommission = this.calculateSumAfterCommission(value)
      return value && sumAfterCommission >= 1000 && sumAfterCommission >= this.company.minimalTransactionSum;
    },
    takeSumProvider() {
      this.isLoading = true


      if (this.isSumValid(this.value) && this.firstStep) {
        this.doVerification(this.company.verificationType)
      } else {
        this.handleInvalidSum(this.value)
        this.isLoading = false
      }
    },
    doVerification(type) {

      const cardNumber = this.$form.input('cardNumber').value ?
        this.$form.input('cardNumber').value :
        this.card.title 
    
      const data = {
        amount: this.value,
        pan: cardNumber.replace(/\s+/g, '').trim(),
        cardHolder: this.cardHolder,
        cardId: this.card.id === 'add' ? '' : this.card.id
      }

      const typeMethod = {
        // BIOMETRIC: () => {
        //   // this.doTransaction()
        // },
        OTP: () => {
          $api.transactions.createTransaction(data)
            .then((response) => {
              if (response.status === 200) {
                this.loadDocument()
                this.showDocumentModal = true
                this.$store.commit('app/setTransactionData', response.data)
              }
            })
            .catch((error) => {
              const code = error
              let message;

              if (code === '3.28') {
                message = $n.t(code) + this.company.maximalTransactionSum + ' ₸'
              } else {
                message = $n.t(code);
              }
              $app.ui.notify(message || 'Произошла ошибка, попробуйте позднее (код 2)', 'danger')
              this.isLoading = false
            })
        },
        NO: () => {
          $api.transactions.createTransaction(data)
            .then((response) => {
              if (response.status === 200) {

                $api.auth.sendCode(this.user.iin, this.user.phone)
                  .then((responseSendCode) => {
                    if (responseSendCode.data.data) {
                      this.$store.commit('app/setTransactionData', response.data.content) // устанавливаю transactionData в store
                      this.$store.dispatch('app/getStep', { router: this.$router })
                    }
                  })
                  .catch((error) => {
                    console.error('An error occurred.');
                    $app.ui.notify('При отправке кода произошла ошибка, попробуйте позднее', 'danger')
                  })
              }
            })
            .catch((error) => {
              const code = error
              let message;

              if (code === '3.28') {
                message = $n.t(code) + this.company.maximalTransactionSum + ' ₸'
              } else {
                message = $n.t(code);
              }
              $app.ui.notify(message || 'Произошла ошибка, попробуйте позднее (код 2)', 'danger')
              this.isLoading = false
            })
        }
      }
      return typeMethod[type]()
    },

    //* Работа с документом на подписание
    zoomIn() {
      this.scale -= 0.1
      this.loadPDF(this.scale)
    },
    zoomOut() {
      this.scale += 0.1
      this.loadPDF(this.scale)
    },
    async loadPDF(scaleP) {
      const pdfData = this.base64ToUint8Array(this.document.data)
      try {
        const pdf = await pdfjsLib.getDocument({ data: pdfData, }).promise
        this.addSignatureDisable = false

        const container = document.getElementById('pdfContainer')
        container.innerHTML = ''
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          // Создание элемента canvas для каждой страницы
          const page = await pdf.getPage(pageNumber)
          const canvas = document.createElement('canvas')
          canvas.style.display = 'block' // Установка стиля, чтобы canvas не сливались друг с другом
          const context = canvas.getContext('2d')
          if (scaleP === 0) {
            // Расчет масштаба и размеров вьюпорта
            const viewport = page.getViewport({ scale: 1, })
            scaleP = container.offsetWidth / viewport.width
          }
          const scaledViewport = page.getViewport({ scale: scaleP, })

          // Настройка размеров canvas
          canvas.width = scaledViewport.width
          canvas.height = scaledViewport.height

          // Добавление canvas в контейнер
          container.appendChild(canvas)

          // Отрисовка страницы
          const renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
          }
          await page.render(renderContext).promise
        }
      } catch (error) {
        console.error('Error loading PDF')
        $app.ui.notify('Упс... Ошибка загрузки PDF', 'danger')
        this.$var('load', false)
      }
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    closeDocumentModal() {
      $api.eds.updateStatus().then(() => {
      }).catch((response) => {
        this.$var('message', $app.secure.clean(response.response.data.message.replace(/\n/g, '<br>')))
      })
      this.showDocumentModal = false
      this.$router.replace({ name: 'mobile.index', })
    },
    loadDocument() {
      this.isLoading = true

      const document = 'application_otp' 
      $api.document
        .generate(this.user.selectedCompany, document, { 'sum': this.value, })
        .then((response) => {
          if (response.data) {
            this.document = response.data.content
            this.documentId = this.document.id
            this.link = `data:application/pdf;base64,${this.document.data}`
            this.loadPDF(0)
          }
          this.isLoading = false

        }).catch((err) => {
          $app.ui.notify(' Ошибка создания документа', 'danger')
          console.error('An error occurred.');
          this.isLoading = false

        })
    },
    async addSignature() {
      const data = {
        iin: this.user.iin,
        phone: this.user.phone,
        documentId: this.documentId
      }

      $api.auth.sendCodeOtp(data)
        .then((response) => {

          if (response.data.data) {
            this.showDocumentModal = false
            this.$store.dispatch('app/getStep', { router: this.$router })
          }
          
        })
        .catch((error) => {
          this.isLoading = false
          console.error('An error occurred.');
          $app.ui.notify('При отправке кода произошла ошибка, попробуйте позднее', 'danger')
        })
    },

    addProviderCard(type) {
      this.$form.set('cardNumber', '')
      this.showModalAgreement = false

      if (this.card?.id !== 'add') {
        this.isBtnDisabled = false
        this[type] = false
        this.cardNumber = ''
        this.cardHolder = ''
      } else {
        this.isBtnDisabled = false
        this[type] = true
      }
    },
    changeLoadingState(state) {
      this.$var('load', state)
    },
    toggleAgreement() {
      this.agreement = !this.agreement
    },
    openModalAgreement() {
      this.showModalAgreement = !this.showModalAgreement
    },
    updateNeedCheck(value) {
      this.needCheck = value;
    }

    // async takeSumProvider() {
    //   this.$var('load', true)

    //   const cardNumber = this.$form.input('cardNumber').value ?
    //     this.$form.input('cardNumber').value :
    //     this.card.title 
      
    //   const data = {
    //     amount: this.value,
    //     pan: cardNumber.replace(/\s+/g, '').trim(),
    //     cardHolder: this.cardHolder,
    //     cardId: this.card.id === 'add' ? '' : this.card.id
    //   }

    //   if (this.isSumValid(this.value) && this.firstStep) {

    //     try {
    //       const response = await $api.transactions.createTransaction(data)

    //       if (!response.data) {
    //         throw new Error('error')
    //       }

    //       this.$var('load', false)

    //     } catch (error) {
    //       const code = error
    //       let message;

    //       if (code === '3.28') {
    //         message = $n.t(code) + this.company.maximalTransactionSum + ' ₸'
    //       } else {
    //         message = $n.t(code);
    //       }
    //       $app.ui.notify(message || 'Произошла ошибка, попробуйте позднее (код 2)', 'danger')
    //       this.$var('load', false)
    //     }
    //     // $api.transactions
    //     //   .check(this.user.companyId, this.user.id, {
    //     //     sum: this.value,
    //     //     commission: this.calculateCommission(this.value),
    //     //   })
    //     //   .then((response) => {


    //     //     const available = response.data.content.isAvailable
    //     //     this.needVerificationType = response.data.content.type

    //     //     available && this.doVerification(this.needVerificationType)
    //     //     this.isBtnDisabled = true
    //     //     this.$var('load', false)

    //     //   })
    //     //   .catch((response) => {

    //     //     let code = response.response.data.message;
    //     //     let message;

    //     //     if (code === '3.28') {
    //     //       message = $n.t(code) + this.company.maximalTransactionSum + ' ₸'
    //     //     } else {
    //     //       message = $n.t(code);
    //     //     }
    //     //     $app.ui.notify(message, 'danger')
    //     //     this.$var('load', false)
    //     //   })

    //   } else {
    //     this.handleInvalidSum(this.value)
    //     this.$var('load', false)
    //   }
    // },
    // doTransaction() {
    //   this.$var('load', true)

    //   let cardNumber = this.$form.input('cardNumber').value ?
    //     this.$form.input('cardNumber').value :
    //     this.card.title

    //   if (this.user.company.payoutProvider === 'woopay') {
    //     this.hideButton = true
    //     this.provider('woopay', cardNumber)
    //   } else if (this.user.company.payoutProvider === 'bereke') {
    //     this.hideButton = true
    //     this.provider('bereke', cardNumber)
    //   }
    //   this.$var('load', true)
    // },
    // provider(providerType, cardNumber) {

    //   const upperFirstChart = providerType.charAt(0).toUpperCase()

    //   $api.payment[`${providerType}DoPay`]({
    //     cardId: this.card.identifier,
    //     amount: this.value,
    //     pan: cardNumber,
    //     cardHolder: this.cardHolder,
    //     cardHash: this.card.id,
    //     mask: this.card.title,
    //   }).then((response) => {

    //     this.otpData = response.data.content
    //     this.otpData.userCom = this.commission

    //     if (response.data.content) {
    //       if (response.data.content.needSignature === 'true' || response.data.content.needOtp) {
    //         this.berekeCommission = providerType === 'bereke' && response.data.content.commission
    //         this[`${providerType}Amount`] = response.data.content.amount
    //         this[`${providerType}DateTime`] = $app.date.format(response.data.content.dateTime, 'date')
    //         this[`${providerType}OperationId`] = response.data.content.operationId
    //         this.loadDocument()
    //         this.showDocumentModal = true
    //       } else {
    //         if (Object.keys(response.data.content).length === 0) {
    //           this[`show${upperFirstChart}Success`] = false
    //           this.step = 1
    //           $app.ui.notify($n.t('5.5.0'), 'danger')
    //           this.$router.push({ name: 'mobile.index', })
    //         } else {
    //           this[`show${upperFirstChart}Success`] = true
    //           this.showContainer = false
    //           this.berekeCommission = providerType === 'bereke' && response.data.content.commission
    //           this[`${providerType}Amount`] = response.data.content.amount
    //           this[`${providerType}DateTime`] = $app.date.format(response.data.content.dateTime, 'date')
    //           this[`${providerType}OperationId`] = response.data.content.operationId
    //           this.step = 1
    //         }
    //       }
    //     } else {
    //       this[`show${upperFirstChart}Success`] = false
    //       this.step = 1
    //       $app.ui.notify('Ошибка проведения платежа', 'danger')
    //       this.$router.push({ name: 'mobile.index', })
    //     }
    //   }).catch(() => {
    //     $app.ui.notify('Ошибка проведения платежа', 'danger')
    //   }).finally(() => {
    //     this.$var('load', false)
    //   })
    // },
    // async getBiometricLink() {
    //   this.$var('load', true)
    //   await $api.eds.create(this.documentId).then((response) => {
    //     if (response.data.content) {
    //       this.iframeSrc = response.data.content.url
    //       this.$var('load', false)
    //       const documentId = this.documentId
    //       const checkStatus = setInterval(async () => {
    //         try {
    //           await $api.document.getDocumentData(documentId).then((response) => {
    //             if (response.data) {
    //               const document = response.data.content
    //               if (document.status !== 'IN_PROGRESS') {
    //                 clearInterval(checkStatus)
    //                 this.showCameraModal = false
    //                 this.showDocumentModal = false
    //                 if (document.status === 'SIGNED_BY_USER') {
    //                   if (this.user.company.payoutProvider === 'woopay') {
    //                     this.showWoopaySuccess = true
    //                     this.showContainer = false
    //                     this.step = 1
    //                   }
    //                   if (this.user.company.payoutProvider === 'bereke') {
    //                     this.showBerekeSuccess = true
    //                     this.showContainer = false
    //                     this.step = 1
    //                   }
    //                 } else {
    //                   $app.ui.notify('Ошибка проведения платежа', 'danger')
    //                   this.$router.push({ name: 'mobile.index', })
    //                 }
    //               }
    //             }
    //           })
    //         } catch (e) {
    //           // Ошибка доступа, возможно, из-за политик безопасности
    //           $app.ui.notify('Ошибка возможно, из-за политик безопасности', 'danger')

    //         }
    //       }, 1000)
    //     }
    //   }).catch((err) => {
    //     this.$var('load', false)
    //     $app.ui.notify('Произошла непредвиденная ошибка. Повторите позднее', 'danger')
    //   })
    // },
    // getProviderCards(providerName) {
    //   // this.$var('load', true)
    //   this.cards = []
    //   this.cards.push({ id: '', name: 'add', title: '', bank: '', issuer: 'Новая карта', })
    //   $api.payment[providerName]().then((response) => {
    //     if (response.data.content.length > 0) {
    //       $n.map(response.data.content, (e) => {
    //         const cardBin = e.mask.substr(0, 6)
    //         const bank = $n.getBankIcon(cardBin)
    //         this.cards.push({
    //           identifier: e.id,
    //           id: e.uuid,
    //           name: '',
    //           title: e.mask,
    //           issuer: '',
    //           bank: bank.name,
    //           bankIcon: bank.icon,
    //           issuerIcon: '',
    //         })
    //       })
    //     }
    //   }).catch((response) => {
    //     $app.ui.notify('Упс... Не удалось получить активные карты', 'danger')
    //     console.error('An error occurred.');

    //   }).finally(() => {
    //     this.$var('load', false)
    //   })
    // },
    // takeSum() {
    //   if (this.card) {
    //     if (this.value > 0) {
    //       this.$var('load', true)
    //       $api.transactions.check(this.user.companyId, this.user.id, {
    //         sum: this.value,
    //         commission: this.calculateCommission(this.value),
    //       }).then((response) => {
    //         if (response.data.content.isAvailable) {
    //           $api.auth.sendCode(this.user.iin, this.user.phone).then((request) => {
    //             this.showVerify = true
    //           })
    //         }
    //       }
    //       ).catch((response) => {
    //         this.$var('message', $app.secure.clean($n.t(response.response.data.message)))
    //       }).finally(() => {
    //         this.$var('load', false)
    //       })
    //     } else {
    //       if (!this.value) {
    //         this.$var('message', $app.secure.clean($n.t('3.20')))
    //       } else {
    //         this.$var('message', $app.secure.clean($n.t('3.19')) + '1000 ₸')
    //       }
    //     }
    //   } else {
    //     this.$var('selectMessage', $app.secure.clean($n.t('3.18')))
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped src="./payment.scss"></style>
